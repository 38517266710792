// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-brain-health-js": () => import("./../../../src/pages/brain-health.js" /* webpackChunkName: "component---src-pages-brain-health-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-financial-statements-js": () => import("./../../../src/pages/financial-statements.js" /* webpackChunkName: "component---src-pages-financial-statements-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-detail-js": () => import("./../../../src/pages/news-detail.js" /* webpackChunkName: "component---src-pages-news-detail-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-our-team-js": () => import("./../../../src/pages/our-team.js" /* webpackChunkName: "component---src-pages-our-team-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-program-elife-js": () => import("./../../../src/pages/program-elife.js" /* webpackChunkName: "component---src-pages-program-elife-js" */),
  "component---src-pages-program-fedxe-js": () => import("./../../../src/pages/program-fedxe.js" /* webpackChunkName: "component---src-pages-program-fedxe-js" */),
  "component---src-pages-program-the-dot-js": () => import("./../../../src/pages/program-the-dot.js" /* webpackChunkName: "component---src-pages-program-the-dot-js" */),
  "component---src-pages-signaletique-js": () => import("./../../../src/pages/signaletique.js" /* webpackChunkName: "component---src-pages-signaletique-js" */),
  "component---src-pages-smart-tunisia-js": () => import("./../../../src/pages/smart-tunisia.js" /* webpackChunkName: "component---src-pages-smart-tunisia-js" */)
}

