/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// Imports themes and global styles
import './src/shared/styles/index.scss';

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  if (!location.hash.length) {
    document.body.scrollTo(...(currentPosition || [0, 0]));
  }

  return false;
};
